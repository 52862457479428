/* eslint-disable prettier/prettier */
import { create } from "zustand";
import { Profile, User } from "@nilo/codegen";
import { LoginMethods } from "@nilo/auth/utils/platform-config";

type ProfileStore = {
  profile: Profile;
  user: User;
  loginMethod: LoginMethods;
  setProfile: (profile: Profile) => void;
  setUser: (user: User) => void;
  setLoginMethod: (loginMethod: LoginMethods) => void;
};

export const useProfileStore = create<ProfileStore>((set) => ({
  profile: {} as Profile,
  user: {} as User,
  loginMethod: {} as LoginMethods,
  setProfile: (profile) => set({ profile }),
  setUser: (user) => set({ user }),
  setLoginMethod: (loginMethod) => set({ loginMethod })
}));
