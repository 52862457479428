"use client";

import "./globals.css";
import React, { useEffect } from "react";
import { Providers } from "../../providers";
import { FC } from "react";
import { Poppins } from "next/font/google";
import { addImagePath } from "@utils";
import { useSite } from "../../hooks/use-site";
import { localesType } from "@i18n";
import useServiceWorker from "@hooks/use-service-worker";
import { useAnalytics } from "@analytics";

const poppins = Poppins({
  subsets: ["latin"],
  weight: ["400", "500", "600", "700", "800", "900"],
  variable: "--font-poppins"
});

interface Props {
  children: React.ReactNode;
  params: {
    locale: localesType;
  };
}

const RootLayout: FC<Props> = ({ children, params: { locale } }) => {
  const { site } = useSite();
  const { registerSW } = useServiceWorker();
  const { analyticsInit } = useAnalytics("auth");

  useEffect(() => {
    analyticsInit();
    registerSW();
  }, []);

  const Favicon = () => {
    return (
      <>
        {!!site?.favicon && (
          <link
            href={addImagePath(site?.favicon)}
            rel="icon"
            type="image/x-icon"
          />
        )}
      </>
    );
  };

  return (
    <html lang={locale}>
      <head>
        <title>{site?.name || "Tienda"}</title>
        <meta content="#eef1f5" name="theme-color" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, user-scalable=no, viewport-fit=cover"
        />

        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="default" />
        <Favicon />
        <script
          dangerouslySetInnerHTML={{
            __html: `!function(t){function e(){var e=this||self;e.globalThis=e,delete t.prototype._T_}"object"!=typeof globalThis&&(this?e():(t.defineProperty(t.prototype,"_T_",{configurable:!0,get:e}),_T_))}(Object);`
          }}
        />
      </head>
      <body className={poppins.className}>
        <Providers>
          <div className="bg-gray-nilo relative mx-auto !min-h-[100dvh] min-h-[100vh] touch-manipulation">
            {children}
          </div>
        </Providers>
      </body>
    </html>
  );
};

export default RootLayout;
